import { weekDays } from '~/models/week-days'
import { priceLevels } from '~/models/price-levels'
import { restaurantTypes } from '~/models/restaurant-types'
import moment from 'moment'

export function setGeolocation(place: any) {
  console.log(place)
  const fields = [
    {
      field: 'name',
      value: place.name ?? ''
    },
    {
      field: 'postal_code',
      value: getPostalCodeFromGoogle(place)
    },
    {
      field: 'city',
      value: getCityFromGoogle(place)
    },
    {
      field: 'province',
      value: getProvinceNameFromGoogle(place)
    },
    {
      field: 'street',
      value: getStreetFromGoogle(place)
    },
    {
      field: 'formatted_address',
      value: place.formatted_address
    },
    {
      field: 'latitude',
      value: place.geometry?.location.lat() ?? null
    },
    {
      field: 'longitude',
      value: place.geometry?.location.lng() ?? null
    },
    {
      field: 'country',
      value: getCountryFromGoogle(place)
    },
    {
      field: 'country_code',
      value: getCountryCodeFromGoogle(place)
    },
    {
      field: 'businessSchedule',
      value: getBusinessScheduleFromGoogle(place)
    },
    {
      field: 'business_status',
      value: place.business_status ?? ''
    },
    {
      field: 'external_rating',
      value: place.rating ?? 0
    },
    {
      field: 'price_level',
      value: getPriceLevelFromGoogle(place)
    },
    {
      field: 'user_ratings_total',
      value: place.user_ratings_total ? parseInt(place.user_ratings_total) : 0
    },
    {
      field: 'reservable',
      value: place.reservable ?? false
    },
    {
      field: 'wheelchair_accessible_entrance',
      value: place.wheelchair_accessible_entrance ?? false
    },
    {
      field: 'serves',
      value: getServesTypesFromGoogle(place)
    },
    {
      field: 'delivery',
      value: place.delivery ?? false
    },
    {
      field: 'takeout',
      value: place.takeout ?? false
    },
    {
      field: 'website',
      value: place.website ?? ''
    },
    {
      field: 'phone_country_code',
      value: getPhoneCountryCodeFromGoogle(place)
    },
    {
      field: 'phone_number',
      value: getPhoneNumberFromGoogle(place)
    },
    {
      field: 'restaurant_type',
      value: getRestaurantTypeFromGoogle(place)
    },
    {
      field: 'place_id',
      value: place.place_id ?? null
    }
  ]
  return fields
}

function getPostalCodeFromGoogle(place: { address_components: any[] }) {
  let postalCode = null
  if (place?.address_components?.length) {
    postalCode =
      place?.address_components?.find((i: { types: string | string[] }) =>
        i.types.includes('postal_code')
      )?.short_name ?? ''
  }
  return postalCode
};
function getCityFromGoogle(place: { address_components: any[]; vicinity: any }) {
  let city = null
  if (place?.address_components?.length) {
    city = place?.address_components?.find((i: { types: string | string[] }) =>
      i.types.includes('locality')
    )?.short_name
      ? place?.address_components?.find((i: { types: string | string[] }) =>
        i.types.includes('locality')
      )?.long_name
      : place?.address_components?.find((i: { types: string | string[] }) =>
        i.types.includes('administrative_area_level_2')
      )?.long_name ?? ''
  }
  return city
};
function getProvinceNameFromGoogle(place: { address_components: any[] }) {
  let data = null
  if (place?.address_components?.length) {
    data =
      place?.address_components?.find((i: { types: string | string[] }) =>
        i.types.includes('administrative_area_level_2')
      )?.long_name ?? ''
  }
  return data
};
function getStreetFromGoogle(place: { address_components: any[] }) {
  let street = null
  if (place?.address_components?.length) {
    street =
      place.address_components?.find((i: { types: string | string[] }) => i.types.includes('route'))
        ?.long_name ?? ''
    street = place.address_components.find((i: { types: string | string[] }) =>
      i.types.includes('street_number')
    )
      ? `${street} ${place.address_components.find((i: { types: string | string[] }) =>
        i.types.includes('street_number')
      ).long_name
      }`
      : `${street}`
  }
  return street
};
function getCountryFromGoogle(place: { address_components: any[] }) {
  let country = null
  if (place?.address_components?.length) {
    country =
      place.address_components?.find((i: { types: string | string[] }) => i.types.includes('country'))
        ?.long_name?.normalize('NFD').replace(/[\u0300-\u036f]/g, '') ?? ''
  }
  return country
};

function getCountryCodeFromGoogle(place: { address_components: any[] }) {
  let countryCode = null
  if (place?.address_components?.length) {
    countryCode =
      place.address_components?.find((i: { types: string | string[] }) => i.types.includes('country'))
        ?.short_name ?? ''
  }
  return countryCode
};

function getBusinessScheduleFromGoogle(place: { opening_hours: any }) {
  let openingHours: { day: string; day_number: any; timePeriods: { start: string; close: string }[] }[] = []
  if (place?.opening_hours?.periods?.length) {
    place.opening_hours.periods
      .sort((a: { open: { day: number } }, b: { open: { day: number } }) => a.open.day - b.open.day)
      .forEach((period: { open: { day: number; hours: any; minutes: any }; close: { day: number; hours: any; minutes: any } }) => {
        if (period.open && period.close) {
          const day = openingHours.find(i => i.day_number === period.open.day) ?? undefined
          if (day) {
            day.timePeriods.push({
              start: `${moment().format('YYYY-MM-DD')} ${('0' + period.open.hours).slice(-2)}:${('0' + period.open.minutes).slice(-2)}:00`,
              close: `${period.open.day !== period.close.day ? moment().add(1, 'days').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')} ${('0' + period.close.hours).slice(-2)}:${('0' + period.close.minutes).slice(-2)}:00`
            })
          } else {
            openingHours.push({
              day: weekDays[period.open.day],
              day_number: period.open.day,
              timePeriods: [
                {
                  start: `${moment().format('YYYY-MM-DD')} ${('0' + period.open.hours).slice(-2)}:${('0' + period.open.minutes).slice(-2)}:00`,
                  close: `${period.open.day !== period.close.day ? moment().add(1, 'days').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')} ${('0' + period.close.hours).slice(-2)}:${('0' + period.close.minutes).slice(-2)}:00`
                }
              ]
            })
          }
        }
      })
  }
  return {
    openingHours: openingHours.map(i => {
      return {
        day: i.day,
        timePeriods: i.timePeriods
      }
    })
  }
}

function getPriceLevelFromGoogle(place: { price_level: number }) {
  let priceLevel = null
  if (place?.price_level && priceLevels[place.price_level]?.name) {
    priceLevel = priceLevels[place.price_level].name
  }
  return priceLevel
}

function getServesTypesFromGoogle(place: any) {
  let servesTypes = []
  if (place.serves_beer) {
    servesTypes.push('SERVES_BEER')
  }
  if (place.serves_dinner) {
    servesTypes.push('SERVES_DINNER')
  }
  if (place.serves_lunch) {
    servesTypes.push('SERVES_LUNCH')
  }
  if (place.serves_wine) {
    servesTypes.push('SERVES_WINE')
  }
  if (place.serves_vegetarian_food) {
    servesTypes.push('SERVES_VEGETARIAN_FOOD')
  }
  if (place.serves_brunch) {
    servesTypes.push('SERVES_BRUNCH')
  }
  if (place.serves_breakfast) {
    servesTypes.push('SERVES_BREAKFAST')
  }
  return servesTypes
}

function getPhoneCountryCodeFromGoogle(place: { international_phone_number: string }) {
  let phoneCountry = null
  if (place?.international_phone_number?.split(' ')[0].includes('+')) {
    phoneCountry = place.international_phone_number.split(' ')[0]
  }
  return phoneCountry
}

function getPhoneNumberFromGoogle(place: { international_phone_number: string }) {
  let phoneNumber = null
  let phoneCountry = getPhoneCountryCodeFromGoogle(place)
  if (place?.international_phone_number && phoneCountry) {
    phoneNumber = place.international_phone_number.replaceAll(phoneCountry, '').replaceAll(/\s/g, '')
  }
  return phoneNumber
}

function getRestaurantTypeFromGoogle(place: any) {
  let type = ''
  if (place.types?.length) {
    for (var i = 0; i < place.types.length; i++) {
      if (restaurantTypes.find(j => j === place.types[i])) {
        type = place.types[i]
        break
      }
    }
  }
  return type
}